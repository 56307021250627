import React from "react";
import Layout from "../components/layout";
import SEO from '../components/seo';

const Imprint = () => {
    return (
        <Layout>
            <SEO title="Imprint" />
            <div className="container pl-3 text-center">
                <h5>Angaben gem&auml;&szlig; &sect; 5 TMG:</h5>
                <p className="pl-2">Manuel Dahnert<br/>
                    Marlene-Dietrich-Str. 3<br/>
                    80636 München</p>

                <h5>Contact:</h5>
                <p className="pl-2">E-Mail: <a href="mailto:manuel.dahnert@gmail.com">
                    manuel.dahnert@gmail.com</a></p>
            </div>
        </Layout>
    );
};

export default Imprint;